import config from '@/config/global'

export default {
  name: 'additionalFinancialInformation',
  routeName: 'additional-financial-information',
  formTitle: 'Additional Financial Information',
  navTitle: 'Additional Financial Information',
  completed: null,
  maxGroupColumns: 12,
  groups: [
    {
      fields: [
        [
          {
            choice: 'single',
            field: 'input',
            type: 'radio',
            isInline: true,
            name: 'unpaidItems12Months',
            veeAs: ' ',
            label: 'Does your business have any unpaid items from the past 12 months?',
            placeholder: '',
            rules: 'required',
            options: [
              { label: 'Yes', value: 'Yes' },
              { label: 'No', value: 'No' }
            ],
            selected: ''
          }
        ],
        [
          {
            choice: 'single',
            field: 'input',
            type: 'number',
            name: 'businessUnpaidItemCount',
            veeAs: 'unpaid items',
            label: 'How many?',
            placeholder: '',
            rules: 'required|decimal|min_value:1|max_value:999',
            dependsOn: [
              {
                fieldName: 'unpaidItems12Months',
                values: ['Yes']
              }
            ],
            selected: ''
          }
        ]
      ]
    },
    {
      title: '',
      fields: [
        [
          {
            choice: 'single',
            field: 'input',
            type: 'radio',
            isInline: true,
            name: 'taxArreas',
            veeAs: ' ',
            label: 'Is your business behind with any tax payments?',
            placeholder: '',
            rules: 'required',
            options: [
              { label: 'Yes', value: 'Yes' },
              { label: 'No', value: 'No' }
            ],
            selected: ''
          }
        ]
      ]
    },
    {
      title: '',
      fields: [
        [
          {
            choice: 'single',
            field: 'input',
            type: 'radio',
            isInline: true,
            name: 'exesBusOverdraft',
            veeAs: ' ',
            label: 'If you already have a business overdraft, have you gone over the agreed limit within the past 12 months?',
            placeholder: '',
            rules: 'required',
            options: [
              { label: 'Yes', value: 'Yes' },
              { label: 'No', value: 'No' }
            ],
            selected: ''
          }
        ],
        [
          {
            choice: 'single',
            field: 'select',
            type: '',
            name: 'limitOverdrawnMonth',
            veeAs: ' ',
            label: 'Month overdrawn',
            placeholder: '',
            rules: 'required',
            options: [
              { label: 'January', value: 'January' },
              { label: 'February', value: 'February' },
              { label: 'March', value: 'March' },
              { label: 'April', value: 'April' },
              { label: 'May', value: 'May' },
              { label: 'June', value: 'June' },
              { label: 'July', value: 'July' },
              { label: 'August', value: 'August' },
              { label: 'September', value: 'September' },
              { label: 'October', value: 'October' },
              { label: 'November', value: 'November' },
              { label: 'December', value: 'December' }
            ],
            dependsOn: [
              {
                fieldName: 'exesBusOverdraft',
                values: ['Yes']
              }
            ],
            selected: ''
          },
          {
            choice: 'single',
            field: 'select',
            type: '',
            name: 'limitOverdrawnDays',
            veeAs: 'overdrawn days',
            label: 'Days overdrawn',
            placeholder: '',
            rules: {
              required: true,
              min_value: 1,
              max_value: 31
            },
            dependsOn: [
              {
                fieldName: 'exesBusOverdraft',
                values: ['Yes']
              }
            ],
            selected: ''
          }
        ]
      ]
    },
    {
      title: '',
      fields: [
        [
          {
            choice: 'single',
            field: 'input',
            type: 'radio',
            isInline: true,
            name: 'twoYearCcj',
            veeAs: ' ',
            label: 'Have any of your company officials had any County Court Judgements in the past 2 years?',
            placeholder: '',
            rules: 'required',
            options: [
              { label: 'Yes', value: 'Yes' },
              { label: 'No', value: 'No' }
            ],
            selected: ''
          }
        ],
        [
          {
            choice: 'single',
            field: 'input',
            type: 'number',
            name: 'totalCcjAmount',
            veeAs: 'total CCJ amount',
            label: 'Total CCJ value',
            placeholder: '',
            rules: 'required|decimal|min_value:1',
            icon: {
              text: config.whitelabel.country.currency.defaultCurrencySymbol,
              position: 'left'
            },
            dependsOn: [
              {
                fieldName: 'twoYearCcj',
                values: ['Yes']
              }
            ],
            selected: ''
          }
        ]
      ]
    },
    {
      title: '',
      fields: [
        [
          {
            choice: 'single',
            field: 'input',
            type: 'radio',
            isInline: true,
            name: 'mortgageHeld',
            veeAs: ' ',
            label: 'Do you or any company official have a mortgage?',
            placeholder: '',
            rules: 'required',
            options: [
              { label: 'Yes', value: 'Yes' },
              { label: 'No', value: 'No' }
            ],
            selected: ''
          }
        ],
        [
          {
            choice: 'single',
            field: 'input',
            type: 'number',
            name: 'mortgageAmount',
            veeAs: 'mortgage amount',
            label: 'Mortgage Amount',
            placeholder: '',
            rules: 'required|decimal|min_value:1',
            icon: {
              text: config.whitelabel.country.currency.defaultCurrencySymbol,
              position: 'left'
            },
            dependsOn: [
              {
                fieldName: 'mortgageHeld',
                values: ['Yes']
              }
            ],
            selected: ''
          }
        ]
      ]
    },
    {
      title: '',
      fields: [
        [
          {
            choice: 'single',
            field: 'input',
            type: 'radio',
            isInline: true,
            name: 'sixYearPerslDefault',
            veeAs: ' ',
            label: 'Do you know if you or any company official had any personal defaults over the past 6 years?',
            placeholder: '',
            rules: 'required',
            options: [
              { label: 'Yes', value: 'Yes' },
              { label: 'No', value: 'No' }
            ],
            selected: ''
          }
        ],
        [
          {
            choice: 'single',
            field: 'input',
            type: 'text',
            name: 'paymentDefaultsAccountName',
            veeAs: ' ',
            label: 'Account Name',
            placeholder: '',
            rules: 'required',
            dependsOn: [
              {
                fieldName: 'sixYearPerslDefault',
                values: ['Yes']
              }
            ],
            selected: ''
          },
          {
            choice: 'single',
            field: 'input',
            type: 'number',
            name: 'paymentDefaultsAmount',
            veeAs: 'payment amount',
            label: 'Personal defaults amount',
            placeholder: '',
            rules: 'required|decimal|min_value:1',
            icon: {
              text: config.whitelabel.country.currency.defaultCurrencySymbol,
              position: 'left'
            },
            dependsOn: [
              {
                fieldName: 'sixYearPerslDefault',
                values: ['Yes']
              }
            ],
            selected: ''
          }
        ]
      ]
    },
    {
      title: '',
      fields: [
        [
          {
            choice: 'single',
            field: 'input',
            type: 'radio',
            isInline: true,
            name: 'missedPayments12Months',
            veeAs: ' ',
            label: 'Have you or any other official missed any payments on any of your other accounts over the past 12 months?',
            placeholder: '',
            rules: 'required',
            options: [
              { label: 'Yes', value: 'Yes' },
              { label: 'No', value: 'No' }
            ],
            selected: ''
          }
        ],
        [
          {
            choice: 'single',
            field: 'input',
            type: 'text',
            name: 'missedPayments12MonthsCount',
            veeAs: ' ',
            label: 'Number of missed payments?',
            placeholder: '',
            rules: 'required',
            dependsOn: [
              {
                fieldName: 'missedPayments12Months',
                values: ['Yes']
              }
            ],
            selected: ''
          }
        ],
        [
          {
            choice: 'single',
            field: 'input',
            type: 'text',
            name: 'missedPayments12MonthsAccountName',
            veeAs: ' ',
            label: 'Account Name',
            placeholder: '',
            rules: 'required',
            dependsOn: [
              {
                fieldName: 'missedPayments12Months',
                values: ['Yes']
              }
            ],
            selected: ''
          },
          {
            choice: 'single',
            field: 'input',
            type: 'text',
            name: 'missedPayments12MonthsAccountDescription',
            veeAs: ' ',
            label: 'Account Description',
            placeholder: '',
            rules: 'required',
            dependsOn: [
              {
                fieldName: 'missedPayments12Months',
                values: ['Yes']
              }
            ],
            selected: ''
          }
        ]
      ]
    },
    {
      title: '',
      fields: [
        [
          {
            choice: 'single',
            field: 'input',
            type: 'radio',
            isInline: true,
            name: 'missedPayments6Months',
            veeAs: ' ',
            label: 'Do you know if you or any company official has missed any payments in any of your personal accounts over the past 6 months?',
            placeholder: '',
            rules: 'required',
            options: [
              { label: 'Yes', value: 'Yes' },
              { label: 'No', value: 'No' }
            ],
            selected: ''
          }
        ],
        [
          {
            choice: 'single',
            field: 'input',
            type: 'text',
            name: 'missedPayments6MonthsCount',
            veeAs: ' ',
            label: 'Number of missed payments?',
            placeholder: '',
            rules: 'required',
            dependsOn: [
              {
                fieldName: 'missedPayments6Months',
                values: ['Yes']
              }
            ],
            selected: ''
          }
        ],
        [
          {
            choice: 'single',
            field: 'input',
            type: 'text',
            name: 'missedPayments6MonthsAccountName',
            veeAs: ' ',
            label: 'Account Name',
            placeholder: '',
            rules: 'required',
            dependsOn: [
              {
                fieldName: 'missedPayments6Months',
                values: ['Yes']
              }
            ],
            selected: ''
          },
          {
            choice: 'single',
            field: 'input',
            type: 'text',
            name: 'missedPayments6MonthsAccountDescription',
            veeAs: ' ',
            label: 'Account Description',
            placeholder: '',
            rules: 'required',
            dependsOn: [
              {
                fieldName: 'missedPayments6Months',
                values: ['Yes']
              }
            ],
            selected: ''
          }
        ]
      ]
    },
    {
      title: '',
      fields: [
        [
          {
            choice: 'single',
            field: 'input',
            type: 'radio',
            isInline: true,
            name: 'oldestCreditAccAge',
            veeAs: ' ',
            label: "Do you know how many months you've had your oldest personal credit account for?",
            placeholder: '',
            rules: 'required',
            options: [
              { label: 'Yes', value: 'Yes' },
              { label: 'No', value: 'No' }
            ],
            selected: ''
          }
        ],
        [
          {
            choice: 'single',
            field: 'input',
            type: 'number',
            name: 'oldestCreditAccountAgeMonths',
            veeAs: 'months',
            label: 'Months',
            placeholder: '',
            rules: 'required|decimal|min_value:1',
            dependsOn: [
              {
                fieldName: 'oldestCreditAccAge',
                values: ['Yes']
              }
            ],
            selected: ''
          }
        ]
      ]
    },
    {
      title: '',
      fields: [
        [
          {
            choice: 'single',
            field: 'input',
            type: 'radio',
            isInline: true,
            name: 'bankrupt',
            veeAs: ' ',
            label: 'Have any of your company officials been declared bankrupt?',
            placeholder: '',
            rules: 'required',
            options: [
              { label: 'Yes', value: 'Yes' },
              { label: 'No', value: 'No' }
            ],
            selected: ''
          }
        ]
      ]
    },
    {
      title: '',
      fields: [
        [
          {
            choice: 'single',
            field: 'input',
            type: 'radio',
            isInline: true,
            name: 'failedBusiness',
            veeAs: ' ',
            label: 'Have any of your company officials been involved in a failed business in the past 2 years?',
            placeholder: '',
            rules: 'required',
            options: [
              { label: 'Yes', value: 'Yes' },
              { label: 'No', value: 'No' }
            ],
            selected: ''
          }
        ]
      ]
    }
  ]
}
