var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"ui stackable container grid row companyprofile"},[_c('nav',{staticClass:"four wide column col-xs-12 col-sm-12 col-md-4 col-lg-4"},[_c('BackBtn'),_c('h2',{staticClass:"navigation-title h2"},[_vm._v(_vm._s(_vm.financialInformationForm.navTitle))]),_c('ul',{staticClass:"list-group"},[_c('li',[_c('a',{staticClass:"list-group-item-navigator router-link-active",attrs:{"title":_vm.financialInformationForm.navTitle}},[_c('span',{staticClass:"text"},[_vm._v(_vm._s(_vm.financialInformationForm.navTitle))]),(!_vm.isLoading)?[(_vm.financialInformationForm.completed)?_c('span',{staticClass:"complete-icon"},[_c('i',{staticClass:"material-icons"},[_vm._v("check")])]):_c('span',{staticClass:"progress"},[(_vm.stepProgress)?[_vm._v(_vm._s(_vm.stepProgress.completed)+"/"+_vm._s(_vm.stepProgress.total))]:_vm._e()],2)]:_vm._e()],2)])])],1),_c('section',{staticClass:"twelve wide column col-xs-12 col-sm-12 col-md-8 col-lg-8"},[_c('div',{staticClass:"row"},[_c('header',{staticClass:"col-xs-12 col-md-12"},[_c('h3',{staticClass:"h1 form-title"},[_vm._v(_vm._s(_vm.financialInformationForm.formTitle))])]),_c('div',{staticClass:"col-xs-12 col-md-12 form-company-inner"},[(_vm.isLoading)?_c('div',{staticClass:"align-form"},[_c('div',{staticClass:"ui active inverted dimmer page-loader"},[_c('div',{staticClass:"ui medium text loader"},[_c('spinner',{attrs:{"size":64,"loader":true,"color":""}})],1)])]):_c('DynamicForm',{staticClass:"out-form align-form company-profile-form",attrs:{"submit-promise":_vm.submitPromise,"submit-text":"save"},on:{"df-submit":_vm.submitFinancialInformationForm},scopedSlots:_vm._u([{key:"limitOverdrawnDays",fn:function(ref){
var field = ref.field;
var fieldSet = ref.fieldSet;
var group = ref.group;
var value = ref.value;
return [(!field.dependsOn || _vm.isFieldVisible(field, group, value.groups))?_c('ValidationProvider',{key:field.veeName ? field.veeName : field.name,staticClass:"field",attrs:{"rules":Object.assign({}, field.rules, {max_value: _vm.getLimitOverdrawnDaysMaxValue(fieldSet)}),"name":field.veeAs ? field.veeAs : field.name,"vid":field.veeName ? field.veeName : field.name,"tag":"div"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('div',{staticClass:"field"},[_c('label',[_vm._v(_vm._s(field.label))]),_c('select',{directives:[{name:"model",rawName:"v-model",value:(field.selected),expression:"field.selected"}],staticClass:"ui selection dropdown",class:{ empty: field.selected === '' },attrs:{"name":field.veeName ? field.veeName : field.name,"multiple":field.choice === 'multiple'},on:{"change":function($event){var $$selectedVal = Array.prototype.filter.call($event.target.options,function(o){return o.selected}).map(function(o){var val = "_value" in o ? o._value : o.value;return val}); _vm.$set(field, "selected", $event.target.multiple ? $$selectedVal : $$selectedVal[0])}}},[_c('option',{attrs:{"value":"","hidden":"","disabled":"","selected":""}},[_vm._v(_vm._s(field.placeholder))]),_vm._l((_vm.getLimitOverdrawnDaysMaxValue(fieldSet)),function(n){return _c('option',{key:n,domProps:{"value":n}},[_vm._v(_vm._s(n))])})],2)]),_c('FieldError',_vm._b({staticClass:"ui pointing above red basic label"},'FieldError',{ errors: errors },false))]}}],null,true)}):_vm._e()]}}]),model:{value:(_vm.financialInformationForm),callback:function ($$v) {_vm.financialInformationForm=$$v},expression:"financialInformationForm"}})],1)])])])}
var staticRenderFns = []

export { render, staticRenderFns }